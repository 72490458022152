<template>
  <div class="aboutUs">
    <div class="aboutUs__logo-container">
      <div>关于我们</div>
      <div>德行天下 志在千里</div>
    </div>
    <div class="aboutUs__intro-container">
      <div class="aboutUs__intro-title">关于我们</div>
      <el-row :gutter="60">
        <el-col :span="12">
          <img src="./aboutUs.png" srcset="./aboutUs.png,./aboutUs@2x.png 2x" alt="">
        </el-col>
        <el-col :span="12" class="aboutUs__intro-text">
          <div class="aboutUs__intro-text-p1">
            杭州里德通信有限公司是一家专业从事电子类产品研发、生产、销售&储能电池管理系统(BMS) , 以及锂电池终端应用产品的研发、生产、销售和服务于一体的国家高新技术企业
            。公司已通过ISO9001、ISO14001、ISO45001和IATF16949，已导入ERP资源管理系统,并自主开发MES系统。
          </div>
          <div>
            已经取得发明专利1项，软件著作权9项、实用新型44项。与国内安防及锂电池行业龙头企业均有全面深入的合作；如超威、天能、南都 , 莱克等。直接或间接的成为吉利、联想、小米、天猫等国际一线品牌的供应商。
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="aboutUs__manuCap-container">
      <div class="aboutUs__manuCap-title">制造加工能力</div>
      <el-row :gutter="30">
        <el-col :span="8">
          <div class="aboutUs__manuCap-item">
            <img src="./1.png" srcset="./1.png,./1@2x.png" alt="">
            <div>对0201器件、微型BGA等特殊器件有成熟的装贴经验</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="aboutUs__manuCap-item">
            <img src="./2.png" srcset="./2.png,./2@2x.png" alt="">
            <div>对0402chips、 CSP﹑BCC等特殊器件的贴装有一定经验</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="aboutUs__manuCap-item">
            <img src="./3.png" srcset="./3.png,./3@2x.png" alt="">
            <div>每月生产能力超过4.5亿chips</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="8">
          <div class="aboutUs__manuCap-item">
            <img src="./4.png" srcset="./4.png,./4@2x.png" alt="">
            <div>为多家国内知名企业提供 EMS/OEM服务</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="aboutUs__manuCap-item">
            <img src="./5.png" srcset="./5.png,./5@2x.png" alt="">
            <div>2010年开始从事SMT技术的OEM产品加工</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="aboutUs__manuCap-item">
            <img src="./6.png" srcset="./6.png,./6@2x.png" alt="">
            <div>拥有监控、检测、维修设备</div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="aboutUs__enterpriseStyle-container">
      <div class="aboutUs__enterpriseStyle-title">企业风貌</div>
      <el-row :gutter="24">
        <el-col :span="6">
          <img src="./7.png" srcset="./7.png,./7@2x.png 2x" alt="">
        </el-col>
        <el-col :span="6">
          <img src="./8.png" srcset="./8.png,./8@2x.png 2x" alt="">
        </el-col>
        <el-col :span="6">
          <img src="./9.png" srcset="./9.png,./9@2x.png 2x" alt="">
        </el-col>
        <el-col :span="6">
          <img src="./10.png" srcset="./10.png,./10@2x.png 2x" alt="">
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <img src="./11.png" srcset="./11.png,./11@2x.png 2x" alt="">
        </el-col>
        <el-col :span="6">
          <img src="./12.png" srcset="./12.png,./12@2x.png 2x" alt="">
        </el-col>
        <el-col :span="6">
          <img src="./13.png" srcset="./13.png,./13@2x.png 2x" alt="">
        </el-col>
        <el-col :span="6">
          <img src="./14.png" srcset="./14.png,./14@2x.png 2x" alt="">
        </el-col>
      </el-row>
    </div>

    <div class="aboutUs__coocli-container">
      <div class="aboutUs__coocli-title">合作客户</div>
      <el-row type="flex">
        <el-col :span="5">
          <img height="124" src="./15.png" srcset="./15.png,./15@2x.png 2x" alt="">
        </el-col>
		<el-col :span="5">
		  <img src="./16.png" srcset="./16.png,./16@2x.png 2x" alt="">
		</el-col>
        <el-col :span="5">
          <img src="./17.png" srcset="./17.png,./17@2x.png 2x" alt="">
        </el-col>
        <el-col :span="5">
          <img src="./18.png" srcset="./18.png,./18@2x.png 2x" alt="">
        </el-col>
        <el-col :span="5">
          <img src="./19.png" srcset="./19.png,./19@2x.png 2x" alt="">
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="5">
          <img src="./20.png" srcset="./20.png,./20@2x.png 2x" alt="">
        </el-col>
        <el-col :span="5">
          <img height="124" src="./21.png" srcset="./21.png,./21@2x.png 2x" alt="">
        </el-col>
        <el-col :span="5">
          <img src="./22.png" srcset="./22.png,./22@2x.png 2x" alt="">
        </el-col>
        <el-col :span="5">
          <img src="./23.png" srcset="./23.png,./23@2x.png 2x" alt="">
        </el-col>
        <el-col :span="5">
          <img src="./24.png" srcset="./24.png,./24@2x.png 2x" alt="">
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '@/styles/common.scss';

.aboutUs__logo-container {
  height: rem(360px);
  color: #fff;
  @include bg-image('../ConcatUs/logo');

  div {
    margin-left: rem(380px);
  }

  div:first-of-type {
    font-size: 40px;
    padding-top: rem(140px);
    margin-bottom: 16px;
  }

  div:last-of-type {
    font-size: 20px;
  }
}

.aboutUs__intro-container {
  background-color: #fff;
  font-size: 14px;
  padding: 0 gap() 80px gap();

  .el-row {
    display: flex;
    align-items: center;
  }

  img {
    width: 100%;
  }
}

.aboutUs__manuCap-container {
  padding: 0 gap();
  font-size: 18px;

  .el-row {
    margin-bottom: 30px;
  }
}

.aboutUs__intro-title,
.aboutUs__manuCap-title,
.aboutUs__enterpriseStyle-title,
.aboutUs__coocli-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding-top: 86px;
  padding-bottom: 50px;
}

.aboutUs__intro-text {
  text-indent: 2em;
  line-height: 1.8;
}

.aboutUs__intro-text-p1 {
  margin-bottom: 20px;
}

.aboutUs__manuCap-item {
  background-color: #fff;
  padding: 48px 56px 36px 56px;
  border-radius: 5px;

  div {
    height: 40px;
  }

  img {
    margin-bottom: 36px;
  }
}

.aboutUs__enterpriseStyle-container {
  padding: 0 gap() 80px gap();
  background-color: #fff;

  img {
    width: 100%;
  }

  .el-row {
    margin-bottom: 20px;
  }
}

.aboutUs__coocli-container {
  padding: 0 gap() 68px gap();

  img {
    width: 100%;
  }
}
</style>
